import { mapGetters, mapState } from 'vuex'
import PriceRules from 'theme/mixins/blocks/PriceRules'

export default {
  name: 'Cart',
  mixins: [
    PriceRules
  ],
  methods: {
    uid (e) {
      if (e.uid) return e.uid

      const key = Math.random()
        .toString(16)
        .slice(2)

      this.$set(e, 'uid', key)

      return e.uid
    },
    usingPoints (items) {
      return !!(items.filter(item => ((item.product_option || {}).extension_attributes || {}).qty_using_points > 0) || []).length
    }
  },
  computed: {
    ...mapState({
      cartIsLoaded: (state) => state.cart.cartIsLoaded
    }),
    ...mapGetters({
      productsInCart: 'cart/getCartItems',
      getLastSyncDate: 'cart/getLastSyncDate'

    }),
    isEmptyLoading () {
      return this.productsInCart.length === 0 && !this.cartIsLoaded
    },
    isLoadingLastTransaction () {

    },
    groupedProducts () {
      let groupedProducts = {
        'rco': [],
        'rco_bleu': [],
        'rco_color': [],
        'v76': [],
        'sc': [],
        'elz': [],
        'unclassified': []
      }

      this.productsInCart.forEach(product => {
        switch (product.brand_id) {
          case 1:
            groupedProducts['rco'].push(product)
            break
          case 2:
            groupedProducts['v76'].push(product)
            break
          case 3:
            groupedProducts['sc'].push(product)
            break
          case 4:
            groupedProducts['elz'].push(product)
            break
          case 5:
            groupedProducts['rco_bleu'].push(product)
            break
          case 6:
            groupedProducts['rco_color'].push(product)
            break
          default:
            groupedProducts['unclassified'].push(product)
        }
      })

      return groupedProducts
    },
    brandSubtotal () {
      let brandProducts = this.groupedProducts
      let brandSubtotal = {}

      for (let key in brandProducts) {
        let products = brandProducts[key]

        brandSubtotal[key] = 0

        products.forEach(product => {
          let rulePrice = this.getRulePrice(product)
          let loyaltyQty = ((product.product_option || {}).extension_attributes || {}).qty_using_points || 0

          if (rulePrice) {
            brandSubtotal[key] += rulePrice * (product.qty - loyaltyQty)
          } else {
            brandSubtotal[key] += product.price * (product.qty - loyaltyQty)
          }
        })
      }

      return brandSubtotal
    },
    brandLoyaltySubtotal () {
      let brandProducts = this.groupedProducts
      let brandSubtotal = {}

      for (let key in brandProducts) {
        let products = brandProducts[key]

        brandSubtotal[key] = 0

        products.forEach(product => {
          let rulePrice = this.getRulePrice(product, true)

          let loyaltyQty = ((product.product_option || {}).extension_attributes || {}).qty_using_points || 0

          if (rulePrice) {
            brandSubtotal[key] += rulePrice * (loyaltyQty)
          } else {
            brandSubtotal[key] += product.price * (loyaltyQty)
          }
        })
      }

      return brandSubtotal
    },
    brandsTotal () {
      let subtotals = this.brandSubtotal
      let total = 0

      for (let key in subtotals) {
        total += subtotals[key]
      }

      return total
    },
    brandsLoyaltyTotal () {
      let subtotals = this.brandLoyaltySubtotal
      let total = 0

      for (let key in subtotals) {
        total += subtotals[key]
      }

      return total
    }
  }
}
